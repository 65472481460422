import i18n from '@/plugins/vue-i18n';
import router from '@/router';

export default {
  namespaced: true,

  state: {
    notify: {
      show: false,
      type: 'default',
      message: '',
      linkText: '',
      onLinkClick: () => {},
    },
  },

  getters: {},

  actions: {
    notifyInfo({ commit }, { message, linkText, onLinkClick }) {
      commit('SET_NOTIFY', { show: true, type: 'info', message, linkText, onLinkClick });
    },
    notifySuccess({ commit }, { message, linkText, onLinkClick }) {
      commit('SET_NOTIFY', { show: true, type: 'success', message, linkText, onLinkClick });
    },
    notifyWarning({ commit }, { message, linkText, onLinkClick }) {
      commit('SET_NOTIFY', { show: true, type: 'warning', message, linkText, onLinkClick });
    },
    notifyError({ commit }, { message, linkText, onLinkClick }) {
      commit('SET_NOTIFY', { show: true, type: 'error', message, linkText, onLinkClick });
    },
    notifyDefault({ commit }, { message, linkText, onLinkClick }) {
      commit('SET_NOTIFY', { show: true, type: 'default', message, linkText, onLinkClick });
    },
    notifyExportSuccess({ dispatch }) {
      dispatch('notifySuccess', {
        message: i18n.t('toast.exportSuccess'),
        linkText: 'Clique aqui para acessar',
        onLinkClick: () => router.push({ name: 'RouteDocumentsDownload' }),
      });
    },
    notifyExportError({ dispatch }) {
      dispatch('notifyError', { message: i18n.t('toast.exportError') });
    },
    closeNotify({ commit }) {
      commit('SET_SHOW', false);
    },
  },

  mutations: {
    SET_NOTIFY(state, payload) {
      state.notify = payload;
    },
    SET_SHOW(state, payload) {
      state.notify.show = payload;
    },
  },
};
