import AdminServices from '@/services/adminServices.js';
import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery } from '@/utils/pagination';
import { cloneDeep } from 'lodash';
import groupBy from 'lodash/groupBy';

function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (![undefined, null].includes(filters)) {
    requestData.cnpjs = getOnlyItemIdsArray(filters.cnpjs);
    requestData.filterTerm = filters.filterTerm;
  }

  return requestData;
}

function getOnlyItemIdsArray(items) {
  let ids = [];
  items.forEach((item) => {
    if (![undefined, null].includes(item.itemId)) {
      ids.push(item.itemId);
    }
  });
  return ids;
}

function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

function filtersDefaultState() {
  return {
    cnpjs: [],
  };
}

function maskCnpj(item) {
  return item.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export default {
  namespaced: true,
  state: {
    filtersSaved: filtersDefaultState(),
    loadingHorariosEJornadas: true,
    loadingCnpjFilter: true,
    horariosEJornadasList: [],
    filtersSelect: [],
    horariosEJornadasPagination: paginationDefaultState(),
    scheduleReport: false,
    totalHorariosEJornadas: 0,
    filterTerm: '',
    selectCnpj: [],
    userId: '',
  },
  getters: {
    getFiltersSelect(state) {
      return state.filtersSelect;
    },
    getSelectCnpj(state) {
      return state.selectCnpj;
    },
    getDefaultFilterState() {
      return filtersDefaultState();
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getHorariosEJornadasList(state) {
      return state.horariosEJornadasList;
    },
    getHorariosEJornadasPagination(state) {
      return state.horariosEJornadasPagination;
    },
    getLoadingHorariosEJornadasList(state) {
      return state.loadingHorariosEJornadas;
    },
    getHorariosEJornadasTotal(state) {
      return state.totalHorariosEJornadas;
    },

    getScheduleReport(state) {
      return state.scheduleReport;
    },
  },

  actions: {
    init({ dispatch, commit }, userId) {
      commit('SET_USER_ID', userId);
      dispatch('getCompanies', userId);
      dispatch('fetchHorariosEJornadasList');
    },
    fetchTableByTerm({ dispatch, state, commit }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      filters.filterTerm = payload;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('fetchHorariosEJornadasList');
    },
    clearFilters({ dispatch, commit, state }) {
      let term = state.filtersSaved.filterTerm;
      let filters = filtersDefaultState();
      filters.filterTerm = term;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('fetchHorariosEJornadasList');
    },
    async fetchHorariosEJornadasList({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);
      let paginationObject = state.horariosEJornadasPagination;
      let pagination = definePaginationQuery(paginationObject);

      commit('SET_LOADING_HORARIOS_E_JORNADAS_LIST', true);

      try {
        const { data } = await eSocialService.horarioEJornadas.getHorarioEJornadasList(requestData, pagination);
        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_HORARIOS_E_JORNADAS_LIST', data.content);
        let totalElements = data.totalElements;
        commit('SET_TOTAL_HORARIOS_E_JORNADAS_ITEMS', totalElements);
        commit('SET_LOADING_HORARIOS_E_JORNADAS_LIST', false);
      } catch (error) {
        commit('SET_HORARIOS_E_JORNADAS_LIST', []);
        commit('SET_TOTAL_HORARIOS_E_JORNADAS_ITEMS', 0);
        commit('SET_LOADING_HORARIOS_E_JORNADAS_LIST', false);

        console.error('AG_ERROR Error at horarios e Jornadas List:', error);
      }
    },
    applyFilters({ dispatch, commit, state }, payload) {
      payload.filterTerm = state.filtersSaved.filterTerm;
      commit('SET_FILTERS_SAVED', payload);
      dispatch('fetchHorariosEJornadasList');
    },
    async getCompanies({ commit }, payload) {
      try {
        let companies = [];
        let response = await AdminServices.userEstablishment.get(payload);
        let results = response.data;

        if (results && results.length > 0) {
          let establishments = [];
          results.forEach((item) => {
            establishments.push(item.establishment);
          });

          let groups = groupBy(establishments, 'socialName');

          groups = Object.entries(groups).map(([key, value]) => ({
            company: key,
            establishments: value,
          }));
          var groupId = 1;
          groups.forEach((item) => {
            let group = item.company;
            let groupItems = [];
            item.establishments.forEach((innerItem) => {
              let newGroupItem = {
                itemTitle: innerItem.socialName,
                itemSubtitle: maskCnpj(innerItem.details.cnpj),
                groupId: groupId,
                itemId: innerItem.details.cnpj,
              };
              groupItems.push(newGroupItem);
            });

            companies.push({
              groupTitle: group,
              groupId: groupId,
              items: groupItems,
            });
            groupId++;
          });
        }
        commit('SET_SELECT_CNPJ', companies);
        commit('SET_LOADING_CNPJ_FILTER', false);
      } catch (e) {
        console.error('Error at getCompanies: ', e);
      }
    },
    async exportHorariosEJornadas({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = await eSocialService.horarioEJornadas.exportHorarioEJornadasList(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', responseDash.status === 202);
    },
    setHorariosEJornadasPagination({ commit, state, dispatch }, payload) {
      let isSamePagination =
        payload.itemsPerPage === state.horariosEJornadasPagination.itemsPerPage &&
        payload.page === state.horariosEJornadasPagination.page &&
        payload.sortBy === state.horariosEJornadasPagination.sortBy;

      commit('SET_INCIDENCE_HORARIOS_E_JORNADAS_PAGINATION', payload);

      if (!isSamePagination) {
        dispatch('fetchHorariosEJornadasList', '');
      }
    },
  },

  mutations: {
    SET_INCIDENCE_HORARIOS_E_JORNADAS_PAGINATION(state, payload) {
      state.horariosEJornadasPagination = payload;
    },
    SET_LOADING_HORARIOS_E_JORNADAS_LIST(state, payload) {
      state.loadingHorariosEJornadas = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = payload;
    },
    SET_USER_ID(state, payload) {
      state.userId = payload;
    },
    SET_HORARIOS_E_JORNADAS_LIST(state, payload) {
      state.horariosEJornadasList = payload;
    },
    SET_TOTAL_HORARIOS_E_JORNADAS_ITEMS(state, payload) {
      state.totalHorariosEJornadas = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
    SET_LOADING_CNPJ_FILTER(state, payload) {
      state.loadingCnpjFilter = payload;
    },
    SET_SELECT_CNPJ(state, payload) {
      state.selectCnpj = payload;
    },
  },
};
