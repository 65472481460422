import UtilsDate from '@/utils/UtilsDate';
import dayjs from 'dayjs';
import Vue from 'vue';
import { currencyREAL } from '@/utils/currency';
import { userRolesType } from '@/utils/enums/userRolesType';
import { estadosType } from '../utils/enums/estadosType';
import { pendencyType } from '../utils/enums/pendencyType';
import { respRemunType } from '../utils/enums/respRemunType';
import { situationType } from '../utils/enums/situationType';
import { cnpj } from '../utils/mask';

Vue.filter('cpf', function (value) {
  if (!value) return '';
  value = value.toString();
  if (value.length === 11) return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
  return value;
});

Vue.filter('cnpj', cnpj);

Vue.filter('cnae', function (value) {
  if (!value) return '';
  value = value.toString();
  if (value.length === 3) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{1})?/, '$1.$2');
  if (value.length === 5) return value.replace(/\D/g, '').replace(/^(\d{2})(\d{2})?(\d{1})?/, '$1.$2-$3');
  if (value.length === 7) return value.replace(/\D/g, '').replace(/^(\d{4})(\d{1})?(\d{2})?/, '$1-$2/$3');
  return value;
});

Vue.filter('date', function (value, { mask = 'DD/MM/YYYY', showEmpty = false } = {}) {
  if (!value || value === 'null')
    return !showEmpty ? '' : mask.replace(/[d,D]/g, '-').replace(/[m,M]/g, '-').replace(/[y,Y]/g, '-');
  try {
    return dayjs(value).format(mask);
  } catch (e) {
    return value;
  }
});

Vue.filter('datetime', function (value) {
  return UtilsDate.format(value, 'DD/MM/YYYY HH:mm');
});

Vue.filter('dateAsTime', function (value) {
  if (value) {
    const data = new Date(value);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const hora = data.getHours().toString().padStart(2, '0');
    const minuto = data.getMinutes().toString().padStart(2, '0');
    const horaFormatada = `${dia}/${mes}/${ano} às ${hora}h${minuto}`;
    return horaFormatada;
  }
});
Vue.filter('cep', function (value) {
  if (!value) return '';
  if (value.length === 8) return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})?/, '$1-$2');
  return value;
});

Vue.filter('money', function (value) {
  if (!value) return '';
  try {
    return currencyREAL(value).format();
  } catch (e) {
    return value;
  }
});

Vue.filter('empty', function (value) {
  if (!value) return '---';
  return value;
});

Vue.filter('emptyCurrency', function (value) {
  if (!value) return 'R$ 0,00';
  return value;
});

Vue.filter('userRole', function (value) {
  if (!value) return value;

  const userRole = userRolesType[value];
  if (userRole) return userRole;

  return value;
});

Vue.filter('estados', function (value) {
  if (!value) return value;

  const estado = estadosType[value];
  if (estado) return estado;

  return value;
});
Vue.filter('situation', function (value) {
  if (!value) return value;

  const situation = situationType[value];
  if (situation) return situation;

  return value;
});
Vue.filter('respRemun', function (value) {
  if (!value) return value;

  const respRemun = respRemunType[value];
  if (respRemun) return respRemun;

  return value;
});

Vue.filter('pendency', function (value) {
  if (!value) return value;

  const pendencys = pendencyType[value];
  if (pendencys) return pendencys;

  return value;
});

Vue.filter('pretty', function (value) {
  return JSON.stringify(JSON.parse(value), null, 2);
});
