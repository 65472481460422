import * as roles from '@/assets/json/menuRoles';
import validateRole from '@/middleware/validateRole';
import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const isEmbeded = process.env.VUE_APP_EMBEDED === 'true';

const routes = [
  {
    path: !isEmbeded ? '/login' : '/vobys-login',
    component: () => (!isEmbeded ? import('./layouts/Auth.vue') : import('./views/vobys/Sso.vue')),
    children: [
      {
        path: '',
        name: 'RouteLogin',
        component: () => (!isEmbeded ? import('./views/auth/Login.vue') : import('./views/vobys/Sso.vue')),
      },
      {
        path: 'token/tfa/confirm/:token?',
        name: 'RouteConfirmTokenTfa',
        props: true,
        component: () => import('./views/auth/components/ModalTfaToken.vue'),
      },
      {
        path: 'forgot/email',
        name: 'RouteForgotEmail',
        component: () => import('./views/auth/components/ModalForgotPasswordEmail.vue'),
      },
      {
        path: 'forgot/email/confirm/:token',
        name: 'RouteForgotEmailConfirm',
        props: true,
        component: () => import('./views/auth/components/ModalForgotPasswordConfirm.vue'),
      },
      {
        path: 'registration/first/access/:id',
        name: 'RouteTfaFirstAccess',
        props: true,
        component: () => import('./views/auth/components/TfaFirstAccess.vue'),
      },
      {
        path: 'forbidden',
        name: 'RouteForbidden',
        component: () => import('./views/vobys/Forbidden.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('./layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'RouteHome',
        component: () => import('./views/home/Home.vue'),
      },
      {
        path: 'saude-e-seguranca',
        name: 'RouteHealthCare',
        meta: {
          roles: roles.esocial,
        },
        component: () => import('./layouts/RouterLayout.vue'),
        children: [
          {
            path: 'agentes-nocivos-e-aposentadoria-especial',
            name: 'RouteHealthCareAgentesNocivosAposentadoriaEspecial',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/wss/PageHealthCare.vue'),
          },
          {
            path: 'cat',
            name: 'RouteCAT',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/wss/CAT.vue'),
          },
        ],
      },
      {
        path: 'configuracoes',
        component: () => import('./layouts/RouterLayout.vue'),
        children: [
          {
            path: 'e-cac',
            component: () => import('./layouts/RouterLayout.vue'),
            children: [
              {
                path: 'integracao',
                name: 'RouteSettingsEcacIntegration',
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/fonte-dados/EcacProcess.vue'),
              },
              {
                path: 'mensagens',
                name: 'RouteSettingsEcacMessages',
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/ConfiguracoesEcac.vue'),
              },
            ],
          },
          {
            path: 'usuarios',
            name: 'RouteSettingsUsers',
            meta: {
              roles: roles.admin,
            },
            component: () => import('./views/cadastros-gerenciais/Usuarios.vue'),
          },
          {
            path: 'usuarios/:id',
            name: 'RouteSettingsUser',
            props: true,
            redirect: { name: 'RouteSettingsUserData' },
            component: () => import('./views/cadastros-gerenciais/Usuario.vue'),
            children: [
              {
                path: 'dados',
                name: 'RouteSettingsUserData',
                props: true,
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/usuario/UsuarioDados.vue'),
              },
              {
                path: 'permissoes',
                name: 'RouteSettingsUserPermissions',
                props: true,
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/usuario/UsuarioPermissoes.vue'),
              },
              {
                path: 'redefinir-senha',
                name: 'RouteSettingsUserSecurity',
                props: true,
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/usuario/UsuarioSeguranca.vue'),
              },
              {
                path: 'autenticacao-2fa',
                name: 'RouteSettingsUserAuthentication',
                props: true,
                meta: {
                  middleware: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/usuario/UsuarioAuthentication.vue'),
              },
              {
                path: 'editor-de-menu',
                name: 'RouteSettingsUserEditorMenu',
                props: true,
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/usuario/UsuarioEditorTelas.vue'),
              },
              {
                path: 'estabelecimentos',
                name: 'RouteSettingsUserEstablishments',
                props: true,
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/usuario/UsuarioEstabelecimentos.vue'),
              },
            ],
          },
          {
            path: 'empresas',
            component: () => import('./layouts/RouterLayout.vue'),
            children: [
              {
                path: '',
                name: 'RouteSettingsCompanies',
                meta: {
                  roles: roles.cadastros,
                },
                component: () => import('./views/cadastros-gerenciais/PageEmpresas.vue'),
              },
              {
                path: 'estabelecimentos',
                name: 'RouteSettingsEstablishments',
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/Estabelecimentos.vue'),
              },
              {
                path: 'grupos',
                name: 'RouteSettingsGroups',
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/cadastros-gerenciais/Grupos.vue'),
              },
            ],
          },
          {
            path: 'e-social',
            component: () => import('./layouts/RouterLayout.vue'),
            children: [
              {
                path: 'importacao',
                name: 'RouteSettingsImport',
                meta: {
                  roles: roles.admin,
                },
                component: () => import('./views/fonte-dados/UploadArquivoZip.vue'),
              },
              {
                path: 'tabelas',
                component: () => import('./layouts/RouterLayout.vue'),
                children: [
                  {
                    path: '',
                    name: 'RouteSettingsESocialTables',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/Tables.vue'),
                  },
                  {
                    path: 'natureza-das-rubricas',
                    name: 'RouteSettingsESocialTablesRubricNature',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/RubricNature.vue'),
                  },
                  {
                    path: 'tipos-de-rubrica',
                    name: 'RouteSettingsESocialTablesRubricType',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/RubricType.vue'),
                  },
                  {
                    path: 'incidencias-para-a-cp',
                    name: 'RouteSettingsESocialTablesIncidenceINSS',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/IncidenceINSS.vue'),
                  },
                  {
                    path: 'incidencias-para-o-irrf',
                    name: 'RouteSettingsESocialTablesIncidenceIRRF',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/IncidenceIRRF.vue'),
                  },
                  {
                    path: 'incidencias-para-o-fgts',
                    name: 'RouteSettingsESocialTablesIncidenceFGTS',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/IncidenceFGTS.vue'),
                  },
                  {
                    path: 'incidencias-para-a-cprp',
                    name: 'RouteSettingsESocialTablesIncidenceCPRP',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/IncidenceCPRP.vue'),
                  },
                ],
              },
              {
                path: 'parametros-de-analise',
                component: () => import('./layouts/RouterLayout.vue'),
                children: [
                  {
                    path: '',
                    name: 'RouteSettingsESocialAnalysisParameters',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/AnalysisParameters.vue'),
                  },
                  {
                    path: 'incidencias-por-natureza',
                    name: 'RouteSettingsESocialAnalysisParametersIncidenceNature',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/IncidenceNature.vue'),
                  },
                  {
                    path: 'cbo',
                    name: 'RouteSettingsESocialAnalysisParametersCBO',
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/Cbo.vue'),
                  },
                  {
                    path: 'cbo/cbo-form',
                    name: 'RouteSettingsESocialAnalysisParametersCBOForm',
                    props: true,
                    meta: {
                      roles: roles.admin,
                    },
                    component: () => import('./views/cadastros-gerenciais/e-social/components/CBOForm.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'auditoria',
            name: 'RouteSettingsAudit',
            meta: {
              roles: roles.admin,
            },
            component: () => import('./views/cadastros-gerenciais/Auditoria.vue'),
          },
          {
            path: 'cadastro-de-documentos',
            name: 'RouteSettingsRegistrationDocuments',
            meta: {
              roles: roles.filesUpload,
            },
            component: () => import('./views/documents/file/FileUpload.vue'),
          },
        ],
      },
      {
        path: 'fonte-dados/pis-cofins',
        name: 'RoutePisCofinsUpload',
        meta: {
          roles: roles.pisCofins,
        },
        component: () => import('./views/fonte-dados/PisCofinsUpload.vue'),
      },
      {
        path: 'e-social',
        component: () => import('./layouts/RouterLayout.vue'),
        children: [
          {
            path: 'indicadores',
            name: 'RouteESocialIndicators',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/indicadores/PageIndicadores.vue'),
          },
          {
            path: 'trabalhador',
            component: () => import('./layouts/RouterLayout.vue'),
            children: [
              {
                path: `cbo`,
                name: 'RouteESocialWorkerCBO',
                meta: {
                  roles: roles.cbo,
                },
                component: () => import('./views/eSocial/cbo/Cbo.vue'),
              },
              {
                path: `horarios-jornadas`,
                name: 'RouteESocialWorkerHoursWorkdays',
                meta: {
                  roles: roles.esocial,
                },
                component: () => import('./views/eSocial/HorariosEJornadas/HorariosJornadas.vue'),
              },
              {
                path: `horarios-e-jornadas-history`,
                name: 'RouteESocialWorkerHistoricalHoursWorkdays',
                meta: {
                  roles: roles.esocial,
                },
                component: () => import('./views/eSocial/HorariosEJornadas/components/HorariosJornadasHistory.vue'),
              },
              {
                path: 'visao-geral',
                name: 'RouteESocialWorkerAll',
                meta: {
                  roles: roles.esocial,
                },
                component: () => import('./views/eSocial/Colaboradores.vue'),
              },
              {
                path: 'afastamento-e-inatividade',
                name: 'RouteESocialWorkerAwayInactive',
                meta: {
                  roles: roles.afastadosInativos,
                },
                component: () => import('./views/eSocial/Colaboradores.vue'),
              },
              {
                path: 'cessao',
                name: 'RouteESocialWorkerGiveWay',
                meta: {
                  roles: roles.cessao,
                },
                component: () => import('./views/eSocial/Colaboradores.vue'),
              },
            ],
          },
          {
            path: 'empresas',
            name: 'RouteESocialCompanies',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/eSocial/Empresas.vue'),
          },
          {
            path: 'estabelecimentos',
            name: 'RouteESocialEstablishments',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/eSocial/Estabelecimentos.vue'),
          },
          {
            path: 'relatorios',
            name: 'RouteESocialReports',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/eSocial/Relatorios.vue'),
          },
          {
            path: 'rubricas',
            name: 'RouteESocialRubrics',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/eSocial/Rubricas.vue'),
          },
          {
            path: 'rubricas/:id',
            name: 'RouteESocialRubric',
            props: true,
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/eSocial/Rubrica.vue'),
          },
          {
            path: 'rubricas-new',
            name: 'RouteESocialRubricsNew',
            meta: {
              roles: roles.esocial,
            },
            component: () => import('./views/eSocial/RubricasNew.vue'),
          },
        ],
      },
      {
        path: 'e-cac',
        component: () => import('./layouts/RouterLayout.vue'),
        children: [
          {
            path: 'dctf',
            name: 'RouteEcacDctf',
            meta: {
              roles: roles.ecac,
            },
            component: () => import('./views/ecac/Dctf.vue'),
          },
          {
            path: 'dctfnew',
            name: 'RouteEcacDctfNew',
            meta: {
              roles: roles.ecac,
            },
            component: () => import('./views/ecac/DctfNew.vue'),
          },
          {
            path: 'mensagens',
            name: 'RouteEcacMessages',
            props: true,
            meta: {
              roles: roles.ecacMensagens,
            },
            component: () => import('./views/ecac/Mensagens.vue'),
          },
        ],
      },
      {
        path: 'piscofins/relatorios',
        name: 'RoutePisCofinsReports',
        meta: {
          roles: roles.pisCofins,
        },
        component: () => import('./views/pis-cofins/Relatorios.vue'),
      },
      {
        path: 'ativacao',
        name: 'RouteRegistrationUserActivation',
        props: true,
        meta: {
          roles: roles.admin,
        },
        component: () => import('./views/cadastros-gerenciais/usuario/UsuarioCadastradoAtivacao.vue'),
      },
      {
        path: 'central-de-ajuda',
        name: 'RouteHelpCenter',
        component: () => import('./views/help-desk/Chamados.vue'),
      },
      {
        path: 'minha-conta',
        name: 'RouteLayoutProfile',
        redirect: { name: 'RouteLayoutProfileEdit' },
        component: () => import('./layouts/UserProfile.vue'),
        children: [
          {
            path: 'perfil',
            name: 'RouteLayoutProfileEdit',
            component: () => import('./views/usuario/Perfil.vue'),
          },
          {
            path: 'editar-senha',
            name: 'RouteLayoutProfilePasswordChange',
            component: () => import('./views/usuario/ChangePassword.vue'),
          },
          {
            path: 'autenticacao-2fa',
            name: 'RouteLayoutProfileAuthentication',
            component: () => import('./views/usuario/Authentication2FA.vue'),
          },
        ],
      },
      {
        path: 'certidoes',
        component: () => import('./layouts/RouterLayout.vue'),
        children: [
          {
            path: 'cnd-cpen',
            name: 'RouteCertificatesCnd',
            meta: {
              roles: roles.certidoes,
            },
            component: () => import('./views/certidoes/Certidoes.vue'),
          },
          {
            path: 'historico/:idCertidao',
            name: 'RouteCertificatesHistoric',
            props: true,
            meta: {
              roles: roles.certidoes,
            },
            component: () => import('./views/certidoes/CertidaoHistorico.vue'),
          },
          {
            path: 'crf',
            component: () => import('./layouts/RouterLayout.vue'),
            children: [
              {
                path: '',
                name: 'RouteCrf',
                meta: {
                  roles: roles.crf,
                },
                component: () => import('./views/certidoes/Crf.vue'),
              },
              {
                path: 'historico/:idCrf',
                name: 'RouteCrfHistoric',
                props: true,
                meta: {
                  roles: roles.crf,
                },
                component: () => import('./views/certidoes/CrfHistorico.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'totalizadores',
        name: 'RouteTotals',
        meta: {
          roles: roles.totalizadores,
        },
        component: () => import('./views/totalizador/Totalizadores.vue'),
      },
      {
        path: 'anexos',
        name: 'RouteDocumentsFile',
        meta: {
          roles: roles.filesRead,
        },
        component: () => import('./views/documents/file/File.vue'),
      },
      {
        path: 'exportacoes',
        name: 'RouteDocumentsDownload',
        meta: {
          roles: roles.downloads,
        },
        component: () => import('./views/documents/download/Download.vue'),
      },
    ],
  },
  {
    path: '/playground',
    component: () => import('./layouts/Playground.vue'),
    children: [
      {
        path: '',
        name: 'PlaygroundHome',
        component: () => import('./playground/PlaygroundHome.vue'),
      },
      {
        path: 'button',
        name: 'PlaygroundButton',
        component: () => import('./playground/PlaygroundButtons.vue'),
      },
      {
        path: 'button-icon',
        name: 'PlaygroundButtonIcon',
        component: () => import('./playground/PlaygroundButtonsIcon.vue'),
      },
      {
        path: 'table',
        name: 'PlaygroundTable',
        component: () => import('./playground/PlaygroundTable.vue'),
      },
      {
        path: 'tabs',
        name: 'PlaygroundTabs',
        component: () => import('./playground/PlaygroundTabs.vue'),
      },
      {
        path: 'tags',
        name: 'PlaygroundTags',
        component: () => import('./playground/PlaygroundTags.vue'),
      },
      {
        path: 'toast',
        name: 'PlaygroundToast',
        component: () => import('./playground/PlaygroundToast.vue'),
      },
      {
        path: 'switch',
        name: 'PlaygroundSwitch',
        component: () => import('./playground/PlaygroundSwitch.vue'),
      },
      {
        path: 'menu',
        name: 'PlaygroundMenu',
        component: () => import('./playground/PlaygroundMenu.vue'),
      },
      {
        path: 'text-field',
        name: 'PlaygroundTextField',
        component: () => import('./playground/PlaygroundTextField.vue'),
      },
      {
        path: 'autocomplete',
        name: 'PlaygroundAutocomplete',
        component: () => import('./playground/PlaygroundAutocomplete.vue'),
      },
      {
        path: 'filter',
        name: 'PlaygroundFilter',
        component: () => import('./playground/PlaygroundFilter.vue'),
      },
      {
        path: 'icon',
        name: 'PlaygroundIcon',
        component: () => import('./playground/PlaygroundIcon.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes,
});

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

router.beforeEach((to, from, next) => {
  if (!to.meta?.roles) return next();

  const context = {
    from,
    next,
    router,
    to,
  };
  const payload = {
    to: to.path,
    from: from.path,
  };

  store.dispatch('routerModule/setRoute', payload);

  return validateRole(to.meta.roles, context);
});

export default router;
