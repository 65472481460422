import 'moment/locale/pt-br';
import moment from 'moment';

moment.locale('pt-br');

export default class UtilsDate {
  static today(format = 'YYYY-MM-DD') {
    return moment().format(format);
  }

  static format(value, format = 'DD/MM/Y') {
    if (!value) return;

    return moment(value).format(format);
  }

  static isValid(value, format, strict = false) {
    return moment(value, format, strict).isValid();
  }
}
