import AdminServices from '@/services/adminServices.js';
import UserService from '@/services/userService.js';
import axiosWarSmartSolution from '@/services/warSmartSolutionService.js';
import UtilsDate from '@/utils/UtilsDate';
import { cloneDeep } from 'lodash';
import groupBy from 'lodash/groupBy';

export default {
  namespaced: true,

  state: {
    paginationColab: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    paginationNocAg: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    paginationRiskAlert: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    scheduleReport: false,
    historyImportBlob: '',
    alertBlob: '',
    nociveAgentBlob: '',
    boolForTags: false,
    filterApplied: false,
    boolForFilterButton: 0,
    companiesList: [],
    categoriesList: [],
    loadingTable: false,
    loadingTableAgNocivo: false,
    loadingTableAlerts: false,
    loadingEnsejador: false,
    loadingAposEspecial: false,
    loadingExposedCollaborator: false,
    loadingRetirementRisks: false,
    loadingRiscoExposi: false,
    applyBtnStatus: true,
    competencyInSelected: '',
    companySelected: [],
    categorySelected: [],
    previousEmpresaSelecionada: [],
    previousCategoriaSelecionada: [],
    countNociveAgentsFilter: { cnpjs: [], competency: '', categories: [] },
    countNociveAgents: [],
    countNociveAgentsForFilter: [],
    countRetirementAliquot: [],
    collaborators: [],
    alerts: [],
    totalCollaborators: 0,
    totalNociveAgents: 0,
    totalAlerts: 0,
    collaboratorsSize: 0,
    alertsSize: 0,
    nociveAgentsSize: 0,
    nociveAgents: [],
    competencias: [],
    exposedCollaborators: {},
    retirementRisks: {},
    retirementRisksFilter: [],
    isFromFilterExposed: false,
    isFromFilterSpecial: false,
    textForFilter: [],
    historyModalBool: false,
    nameCpfMatriculation: '',
    nociveAgentCodeDesc: '',
    alertDesc: '',
    historyImport: [],
    lastHistory: '',
    loadingHistory: false,
    maxExportLimit: 250,
  },
  getters: {
    getCompetencias(state) {
      const mappedCompetencias = state.competencias.map((compentencia) => ({
        value: compentencia,
        label: UtilsDate.format(compentencia, 'MM/YYYY'),
      }));
      mappedCompetencias.forEach((competencia, index) => {
        const current = UtilsDate.format(competencia?.value, 'YYYY');
        const next = UtilsDate.format(mappedCompetencias[index + 1]?.value, 'YYYY');
        if (index === 0) mappedCompetencias.splice(0, 0, { label: current, value: current, disabled: true });
        if (next && current && next !== current) {
          mappedCompetencias.splice(index + 1, 0, { label: next, value: next, disabled: true });
        }
      });
      return mappedCompetencias;
    },
    getHistoryImport(state) {
      return state.historyImport;
    },
    getLastHistory(state) {
      return state.lastHistory;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
    getBlobHistoryImport(state) {
      return state.historyImportBlob;
    },
    getNociveAgentBlob(state) {
      return state.nociveAgentBlob;
    },
    getAlertBlob(state) {
      return state.alertBlob;
    },
    getHistoryModalBool(state) {
      return state.historyModalBool;
    },
    getBoolForFilterButton(state) {
      return state.boolForFilterButton;
    },
    getBoolForTags(state) {
      return state.boolForTags;
    },
    getFilterApply(state) {
      return state.filterApplied;
    },
    getCountNociveAgentFilter(state) {
      return state.countNociveAgentsFilter;
    },
    getTextForFilter(state) {
      return state.textForFilter;
    },
    getActiveExposed(state) {
      return state.isFromFilterExposed;
    },
    getActiveSpecialRetirement(state) {
      return state.isFromFilterSpecial;
    },
    getEmpresaSelecionada(state) {
      return state.companySelected;
    },
    geCategoriaSelecionada(state) {
      return state.categorySelected;
    },
    getLoading(state) {
      return state.loading;
    },
    getLoadingTableAgNocivo(state) {
      return state.loadingTableAgNocivo;
    },
    getLoadingTableAlerts(state) {
      return state.loadingTableAlerts;
    },
    getLoadingTable(state) {
      return state.loadingTable;
    },
    getTotalCollaborators(state) {
      return state.totalCollaborators;
    },
    getTotalNociveAgents(state) {
      return state.totalNociveAgents;
    },
    getTotalAlerts(state) {
      return state.totalAlerts;
    },
    getCountNociveAgents(state) {
      return state.countNociveAgents;
    },
    getCountNociveAgentsForFilter(state) {
      return state.countNociveAgentsForFilter;
    },
    getCountRetirementAliquot(state) {
      return state.countRetirementAliquot;
    },
    getCountExposedCollaborators(state) {
      return state.exposedCollaborators;
    },
    getRetirementRisks(state) {
      return state.retirementRisks;
    },
    getRetirementRisksFilter(state) {
      if (state.retirementRisksFilter && !state.retirementRisksFilter.length) {
        return [];
      }
      return state.retirementRisksFilter;
    },
    getCollaborators(state) {
      return state.collaborators;
    },
    getAlerts(state) {
      return state.alerts;
    },
    getNociveAgents(state) {
      return state.nociveAgents;
    },
    companiesList(state) {
      return state.companiesList;
    },
    categoriesList(state) {
      return state.categoriesList.map((item) => {
        return item.subtitle
          ? {
              id: item.subtitle,
              descricao: item.subtitle,
              isGroup: true,
            }
          : { ...item, codigo: item.codigo };
      });
    },
    getCompetencyInSelected(state) {
      return state.competencyInSelected;
    },
    getApplyBtnStatus(state) {
      return state.applyBtnStatus;
    },
    getPaginationColab(state) {
      return state.paginationColab;
    },
    getPaginationNocAg(state) {
      return state.paginationNocAg;
    },
    getPaginationRiskAlert(state) {
      return state.paginationRiskAlert;
    },
    getAllDataLoaded(state) {
      let loaded = false;
      if (
        state.loading &&
        state.loadingEnsejador &&
        state.loadingTableAgNocivo &&
        state.loadingExposedCollaborator &&
        state.loadingRetirementRisks &&
        state.loadingTable
      ) {
        loaded = true;
      }
      return loaded;
    },
  },

  actions: {
    setBoolForFilterButton({ state }, boolForFilterButton) {
      return (state.boolForFilterButton = boolForFilterButton);
    },
    async init({ dispatch }, userId) {
      await dispatch('getCurrentMonth');
      await dispatch('getCategories');

      if (!userId) {
        await dispatch('getCompanies', userId);
      } else {
        let { data } = await UserService.getUser();
        await dispatch('getCompanies', data.id);
      }
    },
    async getCurrentMonth({ commit }) {
      let { data } = await axiosWarSmartSolution.wss.availableCompetencies();
      commit('SET_COMPETENCY', data);
    },
    searchCollaborator({ commit, dispatch, state }, value) {
      commit('SET_LOADINGTABLE', true);
      let filter = cloneDeep(state.countNociveAgentsFilter);
      if (value != '' && value != null) {
        filter.nameCpfMatriculation = value;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.nameCpfMatriculation;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      }

      dispatch('collaboratorRetirementRisks');
    },
    searchNociveAgent({ commit, dispatch, state }, value) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      if (value != '' && value != null) {
        filter.nociveAgentCodeDesc = value;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.nociveAgentCodeDesc;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      }

      dispatch('nociveAgents');
    },
    searchDescription({ commit, dispatch, state }, value) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      if (value != '' && value != null) {
        filter.alertDesc = value;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.alertDesc;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      }

      dispatch('riskAlerts');
    },

    async exportCollaborators({ commit, state }) {
      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisksExport(serialized);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
    async exportNociveAgents({ commit, state }) {
      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisksExport(serialized);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },

    async exportAlerts({ commit, state }) {
      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.riskAlertExport(serialized);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },

    async exportHistoryImport({ commit, state }) {
      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.historyImportExport(serialized);

      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      commit('SET_HISTORYIMPORTBLOB', blob);
    },
    async historyImport({ commit, state }) {
      commit('SET_LOADINGHISTORY', true);

      let serialized = { params: state.countNociveAgentsFilter };

      let response = await axiosWarSmartSolution.wss.historyImport(serialized);
      let last = response.data[response.data.length - 1];
      let splitted = '';
      if (last != undefined) {
        splitted = last.replace('-', 'às');
        commit('SET_LOADINGHISTORY', false);
        commit('SET_LASTHISTORY', splitted);
        commit('SET_HISTORYIMPORT', response.data);
      }
    },

    cleanFilterTags({ commit, state }) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      delete filter.retirementYears;
      delete filter.retirementAliquot;
      delete filter.nociveAgent;
      delete filter.specialRetirement;
      delete filter.onlyExposedRisks;
      commit('SET_BOOLFORTAGS', false);
      commit('SET_FILTERAPPLY', false);
      commit('SET_BOOLFORFILTERBUTTON', 0);

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
    },
    cleanFilterPosition({ commit, state, dispatch }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      let selectedLength = cloneDeep(state.boolForFilterButton);
      if (payload != 'retirementYears' && payload != 'onlyExposedRisks') {
        if (selectedLength != 0) {
          selectedLength--;
        }
        delete filter[payload];
      } else if (payload === 'onlyExposedRisks') {
        if (selectedLength != 0) {
          selectedLength--;
        }
        delete filter[payload];
      } else {
        if (selectedLength != 0) {
          selectedLength--;
        }

        delete filter.retirementYears;
        delete filter.retirementAliquot;
      }
      if (selectedLength < 1) {
        commit('SET_BOOLFORTAGS', false);
        commit('SET_FILTERAPPLY', false);
      }

      commit('SET_BOOLFORFILTERBUTTON', selectedLength);
      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      dispatch('collaboratorRetirementRisks');
    },
    changeNociveAgent({ commit, state }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      let count = cloneDeep(state.boolForFilterButton);
      if (filter.nociveAgent && !payload) {
        if (count > 0) count--;
      }
      if (payload) {
        filter.nociveAgent = payload;
        count++;
        commit('SET_BOOLFORFILTERBUTTON', count);
        commit('SET_BOOLFORTAGS', false);
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.nociveAgent;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
        if (!payload) {
          count = 0;
        }
        if (count > 0) {
          commit('SET_BOOLFORFILTERBUTTON', count);
        } else {
          commit('SET_BOOLFORTAGS', false);
          commit('SET_FILTERAPPLY', false);
          commit('SET_DISABLEAPPLYBUTTON', false);
          commit('SET_BOOLFORFILTERBUTTON', count);
        }
      }
    },
    changeSpecialRetirement({ commit, state, dispatch }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      let count = cloneDeep(state.boolForFilterButton);
      if (filter.specialRetirement && !payload) {
        if (count != 0) count--;
      }
      if (payload) {
        filter.specialRetirement = payload;
        count++;
        commit('SET_BOOLFORFILTERBUTTON', count);
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
        commit('SET_BOOLFORTAGS', false);
      } else {
        delete filter.specialRetirement;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
        if (count != 0) {
          commit('SET_BOOLFORFILTERBUTTON', count);
        } else {
          commit('SET_BOOLFORTAGS', true);
          commit('SET_FILTERAPPLY', false);
          commit('SET_BOOLFORFILTERBUTTON', count);
          dispatch('specificFilter');
        }
      }
    },
    changeRetirementAliquot({ commit, state, dispatch }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      let count = cloneDeep(state.boolForFilterButton);
      if (filter.retirementYears && filter.retirementAliquot && !payload) {
        if (count != 0) count--;
      }
      if (payload) {
        filter.retirementYears = payload.key;
        filter.retirementAliquot = payload.aliquotKey;
        count++;
        commit('SET_BOOLFORFILTERBUTTON', count);
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
        commit('SET_BOOLFORTAGS', false);
      } else {
        delete filter.retirementYears;
        delete filter.retirementAliquot;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
        if (count != 0) {
          commit('SET_BOOLFORFILTERBUTTON', count);
        } else {
          commit('SET_BOOLFORTAGS', true);
          commit('SET_FILTERAPPLY', false);
          commit('SET_BOOLFORFILTERBUTTON', count);
          dispatch('specificFilter');
        }
      }
    },
    changeOnlyExposedRisk({ commit, state, dispatch }, payload) {
      let filter = cloneDeep(state.countNociveAgentsFilter);
      let count = cloneDeep(state.boolForFilterButton);
      if (filter.onlyExposedRisks && !payload) {
        if (count != 0) count--;
      }
      if (payload) {
        filter.onlyExposedRisks = payload;
        count++;
        commit('SET_BOOLFORFILTERBUTTON', count);
        commit('SET_BOOLFORTAGS', false);
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      } else {
        delete filter.onlyExposedRisks;
        commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
        if (count != 0) {
          commit('SET_BOOLFORFILTERBUTTON', count);
        } else {
          commit('SET_BOOLFORTAGS', true);
          commit('SET_FILTERAPPLY', false);
          commit('SET_BOOLFORFILTERBUTTON', count);
          dispatch('specificFilter');
        }
      }
    },
    toggleHistoryModal({ commit }, bool) {
      commit('SET_HISTORYMODALBOOL', bool);
    },

    specificFilter({ commit, dispatch }) {
      let collaborator = [];
      commit('SET_COLLABORATORS', collaborator);
      commit('SET_BOOLFORTAGS', true);
      commit('SET_FILTERAPPLY', true);

      dispatch('collaboratorRetirementRisks');
    },

    applyFilter({ commit, state, dispatch }, isClearing) {
      dispatch('changeApplyBtnStatus', !state.applyBtn);
      commit('SET_LOADING', true);
      dispatch('clearResults');

      let codeSelecteds = [];

      let companiesForFilter = [];
      let categoriesForFilter = [];

      if (state.companySelected.length > 0) {
        companiesForFilter = state.companySelected.map((item) => item.value);
      }

      if (state.categorySelected.length > 0) {
        codeSelecteds = state.categorySelected
          .filter((item) => {
            return !item.subtitle;
          })
          .map((item) => {
            return item.codigo;
          });
        categoriesForFilter = codeSelecteds;
      }

      let competencyForFilter = '';
      if (state.competencyInSelected != '') {
        competencyForFilter = state.competencyInSelected;
      }

      let filter = {
        ...competencyForFilter,
        cnpjs: companiesForFilter,
        categories: categoriesForFilter,
      };

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      commit('SET_LOADING', true);
      commit('SET_LOADINGEXPOSEDCOLABORATOR', true);
      commit('SET_LOADINGRETIREMENTRISKS', true);
      commit('SET_LOADING_TABLE_AGNOCIVO', true);
      commit('SET_LOADINGENSEJADOR', true);

      if (isClearing) return;

      dispatch('getCategories');
      dispatch('historyImport');
      dispatch('collaboratorRetirementRisks');
      dispatch('riskAlerts');
      dispatch('nociveAgents');
      dispatch('chartsCounts');
    },

    setIsFromFilterExposed({ commit, state, dispatch }, bool) {
      if (bool === false) {
        commit('SET_ISFROMFILTERSPECIAL', bool);
      } else if (bool === true) {
        commit('SET_ISFROMFILTERSPECIAL', !bool);
      }
      let filter = cloneDeep(state.countNociveAgentsFilter);

      delete filter.retirementYears;
      delete filter.retirementAliquot;
      delete filter.nociveAgent;
      delete filter.specialRetirement;

      if (bool === true) {
        filter.onlyExposedRisks = true;
      } else {
        delete filter.onlyExposedRisks;
      }

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      dispatch('exposedRisksFromGraph');
      commit('SET_ISFROMFILTEREXPOSED', bool);
    },
    setIsFromFilterSpecial({ commit, state, dispatch }, bool) {
      if (bool === false) {
        commit('SET_ISFROMFILTEREXPOSED', bool);
      } else if (bool === true) {
        commit('SET_ISFROMFILTEREXPOSED', !bool);
      }
      let filter = cloneDeep(state.countNociveAgentsFilter);

      delete filter.retirementYears;
      delete filter.retirementAliquot;
      delete filter.nociveAgent;
      delete filter.onlyExposedRisks;
      if (bool === true) {
        filter.specialRetirement = true;
      } else {
        delete filter.specialRetirement;
      }

      commit('SET_COUNTNOCIVEAGENTSFILTER', filter);
      dispatch('specialRetirementFromGraph');
      commit('SET_ISFROMFILTERSPECIAL', bool);
    },
    async chartsCounts({ state, commit, dispatch }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        return;
      }
      let response = await axiosWarSmartSolution.wss.chartsCounts(state.countNociveAgentsFilter);

      // countExposedColaborators
      if (response.data.exposedCollaborators != undefined) {
        commit('SET_LOADINGEXPOSEDCOLABORATOR', false);
      }
      commit('SET_EXPOSEDCOLLABORATORS', response.data.exposedCollaborators);

      // countRetirementRisks
      if (response.data.retirementRisks != undefined) {
        commit('SET_LOADINGRETIREMENTRISKS', false);
      }
      commit('SET_RETIREMENTRISKS', response.data.retirementRisks);

      let retirementOrNot = [
        { name: `Com Risco (${response.data.retirementRisks.specialRetirements})`, key: 'true' },
        {
          name: `Sem Risco (${response.data.retirementRisks.total - response.data.retirementRisks.specialRetirements})`,
          key: 'false',
        },
      ];
      commit('SET_RETIREMENTRISKSFILTER', retirementOrNot);

      // countNociveAgents
      let nocivos = [];
      // commit('SET_COUNTAGNOCIVOS', nocivos);

      if (Object.keys(response.data).length > 0) {
        nocivos[0] = response.data.nociveAgents.nothing ? response.data.nociveAgents.nothing : 0;
        nocivos[1] = response.data.nociveAgents.chemical ? response.data.nociveAgents.chemical : 0;
        nocivos[2] = response.data.nociveAgents.physical ? response.data.nociveAgents.physical : 0;
        nocivos[3] = response.data.nociveAgents.biological ? response.data.nociveAgents.biological : 0;
        nocivos[4] = response.data.nociveAgents.others ? response.data.nociveAgents.others : 0;
        nocivos[5] = response.data.nociveAgents.all ? response.data.nociveAgents.all : 0;

        commit('SET_LOADING_TABLE_AGNOCIVO', false);
        dispatch('changeApplyBtnStatus', true);
        commit('SET_COUNTAGNOCIVOS', nocivos);
      }

      if (nocivos.length < 1) {
        commit('SET_LOADING_TABLE_AGNOCIVO', false);
      }

      // countRetirementAliquot
      commit('SET_LOADINGENSEJADOR', true);
      let retirementAliquot = [];
      commit('SET_COUNTRETIREMENTALIQUOT', retirementAliquot);

      let textsForFilter = [
        { key: '25', text: '', aliquotKey: '' },
        { key: '20', text: '', aliquotKey: '' },
        { key: '15', text: '', aliquotKey: '' },
      ];
      if (Object.keys(response.data).length != 0) {
        retirementAliquot[0] =
          response.data.retirementYearsAliquot[''] != undefined ? response.data.retirementYearsAliquot[''] : 0;
        retirementAliquot[1] =
          response.data.retirementYearsAliquot['25y-6%'] != undefined
            ? response.data.retirementYearsAliquot['25y-6%']
            : 0;
        textsForFilter[0].text = `25 anos - Aliquota de 6%(${
          response.data.retirementYearsAliquot['25y-6%'] != undefined
            ? response.data.retirementYearsAliquot['25y-6%']
            : 0
        })`;
        textsForFilter[0].aliquotKey = '6';
        retirementAliquot[2] =
          response.data.retirementYearsAliquot['20y-9%'] != undefined
            ? response.data.retirementYearsAliquot['20y-9%']
            : 0;
        textsForFilter[1].text = `20 anos - Aliquota de 9%(${
          response.data.retirementYearsAliquot['20y-9%'] != undefined
            ? response.data.retirementYearsAliquot['20y-9%']
            : 0
        })`;
        textsForFilter[1].aliquotKey = '9';
        retirementAliquot[3] =
          response.data.retirementYearsAliquot['15y-12%'] != undefined
            ? response.data.retirementYearsAliquotata['15y-12%']
            : 0;
        textsForFilter[2].text = `15 anos - Aliquota de 12%(${
          response.data.retirementYearsAliquot['15y-12%'] != undefined
            ? response.data.retirementYearsAliquotata['15y-12%']
            : 0
        })`;
        textsForFilter[2].aliquotKey = '12';
        dispatch('changeApplyBtnStatus', true);
        commit('SET_TEXTSFORFILTER', textsForFilter);
        commit('SET_COUNTRETIREMENTALIQUOT', retirementAliquot);
      } else {
        commit('SET_LOADINGENSEJADOR', false);
      }

      if (retirementAliquot.length > 0) {
        commit('SET_LOADINGENSEJADOR', false);
      } else if (response.error) {
        commit('SET_LOADINGENSEJADOR', false);
      }
    },

    async collaboratorRetirementRisks({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        commit('SET_LOADINGTABLE', false);
        return;
      }
      commit('SET_LOADINGTABLE', true);

      let collaborator = [];
      commit('SET_COLLABORATORS', collaborator);
      let filter = cloneDeep(state.countNociveAgentsFilter);

      let pagination = `page=${state.paginationColab.page - 1}&size=${state.paginationColab.itemsPerPage}`;
      if (state.paginationColab.sortBy.length > 0) {
        pagination = `${pagination}&sort=${state.paginationColab.sortBy[0]},${
          state.paginationColab.sortDesc[0] === true ? 'desc' : state.paginationColab.sortDesc[0] === false ? 'asc' : ''
        }`;
      }
      let serialized = { params: filter };

      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);
      collaborator = response.data.content;

      collaborator.forEach(async (item) => {
        if (item.retirementAliquot) {
          item.retirementAliquot = item.retirementYears + ' anos / ' + item.retirementAliquot + '%';
        } else {
          item.retirementAliquot = 'Ausente';
        }
        item.cpf = item.cpf.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
        item.cpfMask = item.cpf;
        item.socialName = await findSocialName(item, state);
        item.nameAndMatriculation = `${item.name} ${item.matriculation}`;
      });

      commit('SET_TOTAL_COLLABORATORS', response.data.totalElements ? response.data.totalElements : 0);
      commit('SET_COLLABORATORS', collaborator);

      commit('SET_LOADINGTABLE', false);
      commit('SET_COLLABORATORS_SIZE', collaborator.length);
    },

    clearResults({ commit }) {
      commit('SET_COLLABORATORS', []);
      commit('SET_ALERTS', []);
      commit('SET_NOCIVE_AGENTS', []);
      commit('SET_COUNTAGNOCIVOS', []);
      commit('SET_RETIREMENTRISKSFILTER', []);
      commit('SET_EXPOSEDCOLLABORATORS', []);
      commit('SET_RETIREMENTRISKS', []);
      commit('SET_COUNTRETIREMENTALIQUOT', []);
      commit('SET_COUNTNOCIVEAGENTSFILTER', {});
    },

    async riskAlerts({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        commit('SET_LOADING_TABLE_ALERTS', false);
        return;
      }
      let filter = cloneDeep(state.countNociveAgentsFilter);

      commit('SET_LOADING_TABLE_ALERTS', true);

      let alerts = [];
      commit('SET_ALERTS', alerts);

      let pagination = `page=${state.paginationRiskAlert.page - 1}&size=${state.paginationRiskAlert.itemsPerPage}`;

      if (state.paginationRiskAlert.sortBy.length > 0) {
        pagination = `${pagination}&sort=${state.paginationRiskAlert.sortBy[0]},${
          state.paginationRiskAlert.sortDesc[0] === true
            ? 'desc'
            : state.paginationRiskAlert.sortDesc[0] === false
              ? 'asc'
              : ''
        }`;
      }

      let serialized = { params: filter };

      let response = await axiosWarSmartSolution.wss.riskAlerts(serialized, pagination);
      alerts = response.data.content;

      delete filter.onlyExposedRisks;

      commit('SET_TOTAL_ALERTS', response.data.totalElements ? response.data.totalElements : 0);
      commit('SET_ALERTS', alerts);

      commit('SET_LOADING_TABLE_ALERTS', false);
      commit('SET_ALERTS_SIZE', alerts.length);
    },
    async nociveAgentsForFilterSpecific({ state, commit }) {
      let nociveAgents = [];
      commit('SET_NOCIVE_AGENTS', nociveAgents);

      const { itemsPerPage, page } = state.paginationNocAg;
      let serialized = { params: state.countNociveAgentsFilter, page: page - 1, size: itemsPerPage };

      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized);

      nociveAgents = response.data.content;

      let objectForFilter = [];
      Object.entries(nociveAgents).forEach((item) => {
        objectForFilter.push({ name: `${item[0]}(${item[1].length})`, code: item[0] });
      });

      commit('SET_COUNT_NOCIVE_AGENTS_FOR_FILTER', objectForFilter);
    },

    async nociveAgents({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        commit('SET_LOADING_TABLE_AGNOCIVO', false);
        return;
      }
      commit('SET_LOADING_TABLE_AGNOCIVO', true);
      let nociveAgents = [];
      commit('SET_NOCIVE_AGENTS', nociveAgents);

      let pagination = `page=${state.paginationNocAg.page - 1}&size=${state.paginationNocAg.itemsPerPage}`;

      if (state.paginationNocAg.sortBy.length > 0) {
        pagination = `${pagination}&sort=${state.paginationNocAg.sortBy[0]},${
          state.paginationNocAg.sortDesc[0] === true ? 'desc' : state.paginationNocAg.sortDesc[0] === false ? 'asc' : ''
        }`;
      }

      let serialized = { params: state.countNociveAgentsFilter };
      let response = await axiosWarSmartSolution.wss.nociveAgents(serialized, pagination);
      let responseForFilter = await axiosWarSmartSolution.wss.countCollaboratorsByAgent(serialized, pagination);

      nociveAgents = response.data.content;

      nociveAgents.forEach(async (item) => {
        if (item.registeredLimit && item.limitType) {
          item.registeredLimit = item.limitType + ': ' + item.registeredLimit;
        }

        item.socialName = await findSocialName(item, state);
        const lodash = require('lodash');
        item.id = lodash.uniqueId();
      });
      let objectForFilter = [];

      Object.entries(responseForFilter.data).forEach((item) => {
        objectForFilter.push({ name: `${item[0]}(${item[1]})`, code: item[0] });
      });
      commit('SET_LOADING_TABLE_AGNOCIVO', false);
      commit('SET_NOCIVE_AGENTS', nociveAgents);
      commit('SET_COUNT_NOCIVE_AGENTS_FOR_FILTER', objectForFilter);
      commit('SET_TOTAL_NOCIVE_AGENTS', response.data.totalElements ? response.data.totalElements : 0);
      commit('SET_NOCIVE_AGENTS_SIZE', nociveAgents.length);
    },
    async specialRetirementFromGraph({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        return;
      }

      commit('SET_LOADINGTABLE', true);

      let collaborator = [];

      commit('SET_COLLABORATORS', collaborator);

      let pagination = `page=${state.paginationNocAg.page - 1}&size=${state.paginationNocAg.itemsPerPage}`;
      let serialized = { params: cloneDeep(state.countNociveAgentsFilter) };
      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);
      collaborator = response.data.content;

      commit('SET_TOTAL_COLLABORATORS', response.data.totalElements ? response.data.totalElements : 0);

      collaborator.forEach(async (item) => {
        if (item.retirementAliquot) {
          item.retirementAliquot = item.retirementYears + ' anos / ' + item.retirementAliquot + '%';
        } else {
          item.retirementAliquot = 'Ausente';
        }
        item.cpf = item.cpf.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
        item.cpfMask = item.cpf;
        item.socialName = await findSocialName(item, state);
      });

      commit('SET_COLLABORATORS', collaborator);
      commit('SET_LOADINGTABLE', false);
    },
    async exposedRisksFromGraph({ state, commit }) {
      if (!state.competencyInSelected && state.companySelected.length < 1) {
        return;
      }
      commit('SET_LOADINGTABLE', true);

      let collaborator = [];

      commit('SET_COLLABORATORS', collaborator);
      let filter = cloneDeep(state.countNociveAgentsFilter);

      let pagination = `page=${state.paginationNocAg.page - 1}&size=${state.paginationNocAg.itemsPerPage}`;
      let serialized = { params: filter };

      let response = await axiosWarSmartSolution.wss.collaboratorRetirementRisks(serialized, pagination);

      collaborator = response.data.content;

      commit('SET_TOTAL_COLLABORATORS', response.data.totalElements ? response.data.totalElements : 0);

      collaborator.forEach(async (item) => {
        if (item.retirementAliquot) {
          item.retirementAliquot = item.retirementYears + ' anos / ' + item.retirementAliquot + '%';
        } else {
          item.retirementAliquot = 'Ausente';
        }
        item.cpf = item.cpf.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
        item.cpfMask = item.cpf;
        item.socialName = await findSocialName(item, state);
      });

      commit('SET_COLLABORATORS', collaborator);

      commit('SET_LOADINGTABLE', false);
    },

    async changeCompany({ commit, state, dispatch }, payload) {
      if (state.competencyInSelected) {
        dispatch('changeApplyBtnStatus', false);
      }

      commit('SET_PREVIOUSEMPRESASELECIONADA', payload);
      commit('SET_EMPRESASELECIONADA', payload);
    },
    async changeCompetencia({ commit, dispatch }, payload) {
      dispatch('changeApplyBtnStatus', false);
      commit('SET_COMPETENCYINSELECTED', payload);
    },

    async changeCategory({ commit, state }, payload) {
      const categoriesAdded = payload.filter((value) => !state.previousCategoriaSelecionada.includes(value), 0);

      const categoriesRemoved = state.previousCategoriaSelecionada.filter((value) => !payload.includes(value), 0);

      let fullAddition = false;
      for (let i = 0; i < categoriesAdded.length; i++) {
        const c = categoriesAdded[i];
        if (c.subtitle) {
          fullAddition = true;
          break;
        }
      }
      let categoriesSelecteds = [];
      if (categoriesAdded.length > 0 && fullAddition) {
        const lastValueEvent = payload[payload.length - 1];

        if (lastValueEvent.subtitle) {
          state.previousCategoriaSelecionada.forEach((c) => {
            if (
              (c.grupo && c.grupo != lastValueEvent.subtitle) ||
              (c.subtitle && c.subtitle != lastValueEvent.subtitle)
            ) {
              categoriesSelecteds.push(c);
            }
          });

          state.categoriesList.filter((item) => {
            if (item.grupo == lastValueEvent.subtitle || item.subtitle == lastValueEvent.subtitle) {
              if (item.grupo || item.subtitle) {
                categoriesSelecteds.push(item);
              }
            }
          });
        } else {
          categoriesSelecteds.push(payload);
        }

        commit('SET_PREVIOUSCATEGORIASELECIONADA', categoriesSelecteds);
        commit('SET_CATEGORIASELECIONADA', categoriesSelecteds);
      } else {
        commit('SET_PREVIOUSCATEGORIASELECIONADA', payload);
        commit('SET_CATEGORIASELECIONADA', payload);
      }

      if (categoriesRemoved.length > 0 && categoriesRemoved[0].subtitle) {
        payload = payload.filter((item) => item.group !== categoriesRemoved[0].subtitle);

        let selectedOnes = state.previousCategoriaSelecionada.filter(
          (item) => item.grupo !== categoriesRemoved[0].subtitle,
        );

        commit('SET_PREVIOUSCATEGORIASELECIONADA', selectedOnes);
        commit('SET_CATEGORIASELECIONADA', selectedOnes);
      }
    },
    async changeApplyBtnStatus({ commit }, payload) {
      commit('SET_APPLYBTNSTATUS', payload);
      commit('SET_LOADING', false);
    },

    cleanFilters({ commit, dispatch }) {
      commit('SET_LOADING', true);
      commit('SET_COMPETENCYINSELECTED', '');
      commit('SET_EMPRESASELECIONADA', []);
      commit('SET_CATEGORIASELECIONADA', []);
      commit('SET_PREVIOUSEMPRESASELECIONADA', []);
      commit('SET_PREVIOUSCATEGORIASELECIONADA', []);
      commit('SET_RETIREMENTRISKS', {});
      commit('SET_RETIREMENTRISKSFILTER', {});
      commit('SET_EXPOSEDCOLLABORATORS', {});
      commit('SET_COLLABORATORS', []);
      commit('SET_COUNTNOCIVEAGENTSFILTER', { cnpjs: [], competency: '', categories: [] });
      commit('SET_COUNTAGNOCIVOS', []);
      commit('SET_COUNTRETIREMENTALIQUOT', []);
      commit('SET_COLLABORATORS', []);
      commit('SET_ALERTS', []);
      commit('SET_COLLABORATORS_SIZE', 0);
      commit('SET_ALERTS_SIZE', 0);
      commit('SET_NOCIVE_AGENTS', []);
      commit('SET_NOCIVE_AGENTS_SIZE', 0);
      commit('SET_TOTAL_COLLABORATORS', 0);
      commit('SET_TOTAL_NOCIVE_AGENTS', 0);
      commit('SET_TOTAL_ALERTS', 0);
      commit('SET_ISFROMFILTEREXPOSED', false);
      commit('SET_ISFROMFILTERSPECIAL', false);

      dispatch('changeApplyBtnStatus', true);
    },
    async getCompanies({ commit }, payload) {
      let companies = [];
      let response = await AdminServices.userEstablishment.get(payload);
      let results = response.data;

      if (results && results.length > 0) {
        let establishments = [];
        results.forEach((item) => {
          establishments.push(item.establishment);
        });

        let groups = groupBy(establishments, 'socialName');

        groups = Object.entries(groups).map(([key, value]) => ({
          company: key,
          establishments: value,
        }));

        groups.forEach((item) => {
          let group = item.company;
          companies.push({ subtitle: group });
          item.establishments.forEach((innerItem) => {
            innerItem.group = group;

            companies.push(innerItem);
          });
        });
      }

      commit('SET_COMPANIES', companies);
    },
    async getCategories({ commit, state }) {
      let response = await axiosWarSmartSolution.wss.categories(state.countNociveAgentsFilter);
      let results = response.data;

      commit('SET_CATEGORIES', results);
    },
    setPaginationColab({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONCOLAB', payload);

      dispatch('collaboratorRetirementRisks');
    },
    setPaginationNocAg({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONNOCAG', payload);

      dispatch('nociveAgents');
    },
    setPaginationRiskAlert({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONRISKALERT', payload);

      dispatch('riskAlerts');
    },
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companiesList = payload;
    },
    SET_CATEGORIES(state, payload) {
      state.categoriesList = payload;
    },
    SET_APPLYBTNSTATUS(state, payload) {
      state.applyBtnStatus = payload;
    },
    SET_EMPRESASELECIONADA(state, payload) {
      state.companySelected = payload;
    },
    SET_CATEGORIASELECIONADA(state, payload) {
      state.categorySelected = payload;
    },
    SET_COMPETENCYINSELECTED(state, payload) {
      state.competencyInSelected = payload;
    },
    SET_PREVIOUSEMPRESASELECIONADA(state, payload) {
      state.previousEmpresaSelecionada = payload;
    },
    SET_PREVIOUSCATEGORIASELECIONADA(state, payload) {
      state.previousCategoriaSelecionada = payload;
    },
    SET_COUNTNOCIVEAGENTSFILTER(state, payload) {
      state.countNociveAgentsFilter = payload;
    },
    SET_LOADINGEXPOSEDCOLABORATOR(state, payload) {
      state.loadingExposedCollaborator = payload;
    },
    SET_COUNT_NOCIVE_AGENTS_FOR_FILTER(state, payload) {
      state.countNociveAgentsForFilter = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADINGTABLE(state, payload) {
      state.loadingTable = payload;
    },
    SET_LOADING_TABLE_AGNOCIVO(state, payload) {
      state.loadingTableAgNocivo = payload;
    },
    SET_LOADING_TABLE_ALERTS(state, payload) {
      state.loadingTableAlerts = payload;
    },
    SET_LOADINGENSEJADOR(state, payload) {
      state.loadingEnsejador = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
    SET_HISTORYIMPORTBLOB(state, payload) {
      state.historyImportBlob = payload;
    },
    SET_COLLABORATORSEARCH(state, payload) {
      state.nameCpfMatriculation = payload;
    },
    SET_NOCIVE_AGENT_SEARCH(state, payload) {
      state.nociveAgentCodeDesc = payload;
    },
    SET_ALERT_DESC_SEARCH(state, payload) {
      state.alertDesc = payload;
    },
    SET_NOCIVEAGENTBLOB(state, payload) {
      state.nociveAgentBlob = payload;
    },
    SET_ALERTBLOB(state, payload) {
      state.alertBlob = payload;
    },
    SET_LOADINGRETIREMENTRISKS(state, payload) {
      state.loadingRetirementRisks = payload;
    },
    SET_ISFROMFILTEREXPOSED(state, payload) {
      state.isFromFilterExposed = payload;
    },
    SET_ISFROMFILTERSPECIAL(state, payload) {
      state.isFromFilterSpecial = payload;
    },
    SET_TEXTSFORFILTER(state, payload) {
      state.textForFilter = payload;
    },
    SET_COUNTAGNOCIVOS(state, payload) {
      state.countNociveAgents = payload;
    },
    SET_COUNTRETIREMENTALIQUOT(state, payload) {
      state.countRetirementAliquot = payload;
    },
    SET_COLLABORATORS(state, payload) {
      state.collaborators = payload;
    },
    SET_ALERTS(state, payload) {
      state.alerts = payload;
    },
    SET_LOADINGHISTORY(state, payload) {
      state.loadingHistory = payload;
    },
    SET_TOTAL_COLLABORATORS(state, payload) {
      state.totalCollaborators = payload;
    },
    SET_TOTAL_NOCIVE_AGENTS(state, payload) {
      state.totalNociveAgents = payload;
    },
    SET_TOTAL_ALERTS(state, payload) {
      state.totalAlerts = payload;
    },
    SET_COLLABORATORS_SIZE(state, payload) {
      state.collaboratorsSize = payload;
    },
    SET_ALERTS_SIZE(state, payload) {
      state.alertsSize = payload;
    },
    SET_NOCIVE_AGENTS_SIZE(state, payload) {
      state.nociveAgentsSize = payload;
    },
    SET_NOCIVE_AGENTS(state, payload) {
      state.nociveAgents = payload;
    },
    SET_EXPOSEDCOLLABORATORS(state, payload) {
      state.exposedCollaborators = payload;
    },
    SET_RETIREMENTRISKS(state, payload) {
      state.retirementRisks = payload;
    },
    SET_RETIREMENTRISKSFILTER(state, payload) {
      state.retirementRisksFilter = payload;
    },
    SET_COMPETENCY(state, payload) {
      state.competencias = payload;
    },
    SET_HISTORYIMPORT(state, payload) {
      state.historyImport = payload;
    },
    SET_LASTHISTORY(state, payload) {
      state.lastHistory = payload;
    },
    SET_BOOLFORTAGS(state, payload) {
      state.boolForTags = payload;
    },
    SET_FILTERAPPLY(state, payload) {
      state.filterApplied = payload;
    },
    SET_BOOLFORFILTERBUTTON(state, payload) {
      state.boolForFilterButton = payload;
    },
    SET_PAGINATIONCOLAB(state, payload) {
      state.paginationColab = payload;
    },
    SET_PAGINATIONNOCAG(state, payload) {
      state.paginationNocAg = payload;
    },
    SET_PAGINATIONRISKALERT(state, payload) {
      state.paginationRiskAlert = payload;
    },
    SET_HISTORYMODALBOOL(state, payload) {
      state.historyModalBool = payload;
    },
  },
};

function findSocialName(collaborator, state) {
  let empresa = state.companiesList.find((item) => {
    if (item.details != undefined) {
      if (item.details.cnpj == collaborator.cnpj) {
        return item;
      }
    }
  });
  return empresa.details.socialName;
}
