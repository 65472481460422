<template>
  <v-dialog v-model="show" content-class="ag-dialog" scrollable :width="width" :persistent="persistent">
    <v-card class="ag-dialog__card" :max-height="height">
      <v-card-title v-if="title" class="ag-dialog__card-title">
        <span class="ag-dialog__card-title-text">{{ title }}</span>
        <AGButtonIcon v-if="showClose" id="btnCloseX" icon="mdi-close" size="md" @click="close()" />
      </v-card-title>

      <slot name="header" class="ag-dialog__card-title" />

      <v-divider class="ma-0" />

      <v-card-text class="ag-dialog__card-body" :class="{ 'ag-dialog__card-body--no-padding': noPadding }">
        <slot />
      </v-card-text>

      <v-divider v-if="!hideFooterSeparator" class="ma-0" />

      <slot v-if="$slots.footer" name="footer" />

      <v-card-actions v-else class="ag-dialog__card-footer">
        <AGButton
          v-if="!hideSecondaryButton && spaceBetweenButtons"
          type="secondary"
          :left-icon="secondaryButtonIcon"
          :disabled="disabledSecondaryButton"
          :loading="loadingSecondaryButton"
          @click="$emit('secondaryButtonClick')"
        >
          {{ secondaryButtonText }}
        </AGButton>
        <v-spacer />
        <AGButton
          v-if="!hideTertiaryButton"
          type="tertiary"
          :disabled="disabledTertiaryButton"
          :loading="loadingTertiaryButton"
          @click="$emit('tertiaryButtonClick')"
        >
          {{ tertiaryButtonText }}
        </AGButton>
        <AGButton
          v-if="!hideSecondaryButton && !spaceBetweenButtons"
          type="secondary"
          :left-icon="secondaryButtonIcon"
          :disabled="disabledSecondaryButton"
          :loading="loadingSecondaryButton"
          @click="$emit('secondaryButtonClick')"
        >
          {{ secondaryButtonText }}
        </AGButton>
        <AGButton
          v-if="!hidePrimaryButton"
          type="primary"
          :left-icon="primaryButtonIcon"
          :disabled="disabledPrimaryButton"
          :loading="loadingPrimaryButton"
          @click="$emit('primaryButtonClick')"
        >
          {{ primaryButtonText }}
        </AGButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AGButtonIcon from '@/router/views/commons/AGButtonIcon.vue';

import AGButton from './AGButton.vue';

export default {
  components: {
    AGButtonIcon,
    AGButton,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    showClose: {
      type: Boolean,
      default: true,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: '500px',
    },

    height: {
      type: String,
      default: '550px',
    },

    primaryButtonText: {
      type: String,
      default: 'Salvar',
    },

    secondaryButtonText: {
      type: String,
      default: 'Cancelar',
    },

    tertiaryButtonText: {
      type: String,
      default: 'Cancelar',
    },

    hidePrimaryButton: {
      type: Boolean,
      default: false,
    },

    hideSecondaryButton: {
      type: Boolean,
      default: true,
    },

    hideTertiaryButton: {
      type: Boolean,
      default: false,
    },

    primaryButtonIcon: {
      type: String,
    },

    secondaryButtonIcon: {
      type: String,
    },

    spaceBetweenButtons: {
      type: Boolean,
      default: false,
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    disabledPrimaryButton: {
      type: Boolean,
      default: false,
    },

    loadingPrimaryButton: {
      type: Boolean,
      default: false,
    },

    disabledSecondaryButton: {
      type: Boolean,
      default: false,
    },

    loadingSecondaryButton: {
      type: Boolean,
      default: false,
    },

    disabledTertiaryButton: {
      type: Boolean,
      default: false,
    },

    loadingTertiaryButton: {
      type: Boolean,
      default: false,
    },

    hideFooterSeparator: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input', 'close', 'primaryButtonClick', 'secondaryButtonClick', 'tertiaryButtonClick'],

  computed: {
    show: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    close() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.ag-dialog {
  border: 0 !important;

  .ag-dialog__card {
    padding: 0 !important;
    display: flex !important;
    flex-direction: column;

    .ag-dialog__card-title {
      padding: 16px;
      display: flex;
      gap: 12px;
      align-items: flex-start;
      justify-content: space-between;

      .ag-dialog__card-title-text {
        flex: 1;
        word-break: break-word;
      }
    }

    .ag-dialog__card-body {
      overflow-y: auto;
      padding: 16px;
      font-size: 16px;
      color: $neutral-color-high-medium;
      &--no-padding {
        padding: 0 !important;
      }
    }

    .ag-dialog__card-footer {
      padding: 16px;
    }
  }
}
</style>
