export default class ClassIndicadoresFilter {
  constructor() {
    this.establishments = [];
    this.categories = [];
    this.rubrics = [];
    this.periods = '';
  }

  get isEmpty() {
    return !this.establishments.length && !this.categories.length && !this.rubrics.length && !this.periods;
  }

  static fromRouteQuery(routeQuery) {
    if (!routeQuery) return new ClassIndicadoresFilter();

    const instance = new ClassIndicadoresFilter();
    Object.keys(routeQuery).forEach((key) => {
      const value = routeQuery[key];

      if (key in instance) {
        const shouldBeArray = Array.isArray(instance[key]);
        instance[key] = shouldBeArray ? (Array.isArray(value) ? value : [value]) : value;
      }
    });

    return instance;
  }
}
