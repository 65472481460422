import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery } from '@/utils/pagination';
import { cloneDeep } from 'lodash';

function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

function filtersDefaultState() {
  return {
    search: null,
    situation: '',
    typeNature: '',
    cp: '',
    irrf: '',
    fgts: '',
    cprp: '',
    usesTetoRemun: '',
    updatedAt: [],
    updateBy: '',
  };
}

function defaultTables() {
  var tables = [
    {
      id: 1,
      name: 'Incidências por natureza',
      reference: 'incidence_by_nature',
      update: '',
      updatedBy: '',
      to: { name: 'RouteSettingsESocialAnalysisParametersIncidenceNature' },
    },
    {
      id: 2,
      name: 'Classificação Brasileira de Ocupações',
      //precisa passar a referencia do nome no array do endpoint historyLatest (ainda não existe para cbo)
      reference: '',
      update: '',
      updatedBy: '',
      to: { name: 'RouteSettingsESocialAnalysisParametersCBO' },
    },
  ];
  return tables;
}
function toTimestamp(date) {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  return new Date(year, month - 1, day).getTime();
}

function mapFiltersDataForRequest(filters) {
  if (!filters) return {};

  const requestData = {
    rubricNatureCodeNameDesc: filters.search ? `${filters.search}$lk$$nml$` : undefined,
    rubricNatureSituation: filters.situation || null,
    rubricTypeCode: filters.typeNature || null,
    inssIncidenceCode: filters.cp || null,
    irrfIncidenceCode: filters.irrf || null,
    fgtsIncidenceCode: filters.fgts || null,
    cprpIncidenceCode: filters.cprp || null,
    usesTetoRemun: filters.usesTetoRemun === 'Sim' ? true : filters.usesTetoRemun === '' ? undefined : false,
    updatedAt: getUpdatedAt(filters.updatedAt),
    user: filters.updateBy || null,
  };

  return requestData;
}

function getUpdatedAt(updatedAt) {
  if (!updatedAt) return null;

  if (updatedAt.length === 1) {
    const startDate = new Date(updatedAt[0]);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);
    return [startDate.getTime(), endDate.getTime()];
  } else {
    return updatedAt[0] ? [toTimestamp(updatedAt[0]), toTimestamp(updatedAt[1])] : null;
  }
}

function formatHistory(element, incidenceList) {
  let history = {
    user: element.user,
    date: element.date,
    differences: [],
    notes: element.notes || '',
  };

  const diffKeys = [
    { key: 'rubricTypeCode', label: 'Tipo', format: (val) => searchCodeName(val, incidenceList[0]) },
    { key: 'inssIncidenceCode', label: 'INSS', format: (val) => searchCodeName(val, incidenceList[1]) },
    { key: 'irrfIncidenceCode', label: 'IRRF', format: (val) => searchCodeName(val, incidenceList[2]) },
    { key: 'cprpIncidenceCode', label: 'CPRP', format: (val) => searchCodeName(val, incidenceList[3]) },
    { key: 'fgtsIncidenceCode', label: 'FGTS', format: (val) => searchCodeName(val, incidenceList[4]) },
    { key: 'usesTetoRemun', label: 'Teto Remuneratório', format: (val) => (val ? 'Sim' : 'Não') },
  ];

  diffKeys.forEach(({ key, label, format }) => {
    if (element.differences?.before[key]) {
      history.differences.push({
        label,
        before: format ? format(element.differences.before[key]) : element.differences.before[key],
        after: format ? format(element.differences.after[key]) : element.differences.after[key],
      });
    }
  });

  return history.differences.length ? history : null;
}
function searchCodeName(code, incidenceList) {
  const incidence = incidenceList.find((incidence) => incidence.code === code);
  return incidence ? incidence.codeName : '';
}
export default {
  namespaced: true,

  state: {
    tablesForAnalysisParameters: defaultTables(),
    tablesAnalysisParameters: [],
    totalTablesAnalysisParameters: 0,
    filtersSaved: filtersDefaultState(),
    loadingIncidenceNatureList: true,
    loadingFilters: true,
    situationList: [],
    natureTypeList: [],
    incidenceIRRFList: [],
    incidenceFGTSList: [],
    incidenceCPRPList: [],
    incidenceINSSList: [],
    incidenceNatureList: [],
    usesTetoRemunList: [],
    totalIncidenceNature: 0,
    arrHistoric: [],
    incidenceNaturePagination: paginationDefaultState(),
    scheduleReport: false,
  },
  getters: {
    getTablesAnalysisParameters(state) {
      return state.tablesAnalysisParameters;
    },
    getTotalTablesAnalysisParameters(state) {
      return state.totalTablesAnalysisParameters;
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getSituationList(state) {
      return state.situationList;
    },
    getNatureTypeList(state) {
      return state.natureTypeList;
    },
    getIncidenceIRRFList(state) {
      return state.incidenceIRRFList;
    },
    getIncidenceFGTSList(state) {
      return state.incidenceFGTSList;
    },
    getIncidenceCPRPList(state) {
      return state.incidenceCPRPList;
    },
    getIncidenceINSSList(state) {
      return state.incidenceINSSList;
    },
    getIncidenceNatureList(state) {
      return state.incidenceNatureList;
    },
    getUsesTetoRemunList(state) {
      return state.usesTetoRemunList;
    },
    getTotalIncidenceNature(state) {
      return state.totalIncidenceNature;
    },
    getArrHistoric(state) {
      return state.arrHistoric;
    },
    getIncidenceNaturePagination(state) {
      return state.incidenceNaturePagination;
    },
    getLoadingIncidenceNatureList(state) {
      return state.loadingIncidenceNatureList;
    },
    getLoadingFilters(state) {
      return state.loadingFilters;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
    getIncidenceList(state) {
      return [
        state.natureTypeList,
        state.incidenceINSSList,
        state.incidenceIRRFList,
        state.incidenceCPRPList,
        state.incidenceFGTSList,
      ];
    },
  },
  actions: {
    init({ dispatch, commit }) {
      commit('SET_LOADING_INCIDENCE_NATURE_LIST', true);
      commit('SET_LOADING_FILTERS', true);
      commit('SET_INCIDENCE_NATURE_LIST', []);
      commit('SET_NATURE_TYPE_LIST', []);
      commit('SET_INCIDENCE_IRRF_LIST', []);
      commit('SET_INCIDENCE_FGTS_LIST', []);
      commit('SET_INCIDENCE_CPRP_LIST', []);
      commit('SET_INCIDENCE_INSS_LIST', []);
      commit('SET_TOTAL_INCIDENCE_NATURE', 0);
      commit('SET_ARR_HISTORIC', []);

      dispatch('searchIncidenceNature', '');
      dispatch('listIncidences');
    },

    async fetchTablesAnalysisParametes({ commit, state }) {
      let tables = state.tablesForAnalysisParameters;

      const response = await eSocialService.rubricAutomationConfig.historyLatest();
      for (let i = 0; i < tables.length; i++) {
        let property = tables[i].reference;
        if (response.data[property]) {
          tables[i].update = response.data[property].date ? response.data[property].date.replace(' - ', ' às ') : null;
          tables[i].updatedBy = response.data[property].user || null;
        }
      }
      commit('SET_TABLES_ANALYSIS_PARAMETERS', tables);
      commit('SET_TOTAL_TABLES_ANALYSIS_PARAMETERS', tables.length);
    },
    async listIncidences({ commit, dispatch }) {
      dispatch('setLoadingIncidenceNatureList', true);
      dispatch('setLoadingFilters', true);

      try {
        const processResponse = async (serviceMethod, mutation) => {
          const response = await serviceMethod({}, 'page=0&size=9999');
          response.data.content.forEach((type) => {
            type.codeName = `${type.code} - ${type.name}`;
            type.itemTitle = `${type.code} - ${type.name}`;
            type.itemId = type.code;
            type.value = type.code;
          });
          commit(mutation, response.data.content);
        };

        //@todo dados mockados, deve vir do backend
        const situations = [
          { itemTitle: 'Ativo', itemId: 'Ativo', value: true },
          { itemTitle: 'Inativo', itemId: 'Inativo', value: false },
        ];
        //@todo dados mockados, deve vir do backend
        const usesTetoRemun = [
          { itemTitle: 'Sim', itemId: 'Sim', value: true },
          { itemTitle: 'Não', itemId: 'Não', value: false },
        ];
        commit('SET_SITUATION_LIST', situations);
        commit('SET_USERS_TETO_REMUN_LIST', usesTetoRemun);

        await Promise.all([
          processResponse(eSocialService.rubricAutomationConfig.natureTypesList, 'SET_NATURE_TYPE_LIST'),
          processResponse(eSocialService.rubricAutomationConfig.incidenceIRRFList, 'SET_INCIDENCE_IRRF_LIST'),
          processResponse(eSocialService.rubricAutomationConfig.incidenceINSSList, 'SET_INCIDENCE_INSS_LIST'),
          processResponse(eSocialService.rubricAutomationConfig.incidenceFGTSList, 'SET_INCIDENCE_FGTS_LIST'),
          processResponse(eSocialService.rubricAutomationConfig.incidenceCPRPList, 'SET_INCIDENCE_CPRP_LIST'),
        ]);

        dispatch('setLoadingIncidenceNatureList', false);
        dispatch('setLoadingFilters', false);
      } catch (error) {
        dispatch('setLoadingIncidenceNatureList', false);
        dispatch('setLoadingFilters', false);
        console.error('AG_ERROR Error at get incidences list data:', error);
      }
    },
    async fetchFilterSelectsData({ commit, getters, dispatch }, payload) {
      dispatch('setLoadingFilters', true);
      let requestData = mapFiltersDataForRequest(payload);
      let paginationObject = getters.getIncidenceNaturePagination;
      let pagination = definePaginationQuery(paginationObject);
      try {
        const response = await eSocialService.rubricAutomationConfig.incidenceNatureList(requestData, pagination);
        response.data.content.forEach((type) => {
          type.codeName = `${type.rubricNatureCode} - ${type.rubricNatureName}`;
          type.itemTitle = `${type.rubricNatureCode} - ${type.rubricNatureName}`;
          type.itemId = type.rubricNatureCode;
          type.value = type.rubricNatureCode;
        });
        commit('SET_INCIDENCE_NATURE_LIST', response.data.content);
        let totalElements = response.data.totalElements;
        commit('SET_TOTAL_INCIDENCE_NATURE', totalElements);
        dispatch('setLoadingFilters', false);
      } catch (error) {
        console.error('AG_ERROR Error at fetchFilterSelects: ', error);
      }
    },
    async searchIncidenceNature({ commit, dispatch, getters, state }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      filters.search = payload;
      let requestData = mapFiltersDataForRequest(filters);

      let paginationObject = getters.getIncidenceNaturePagination;
      let pagination = definePaginationQuery(paginationObject);

      dispatch('setLoadingIncidenceNatureList', true);
      dispatch('setLoadingFilters', true);
      try {
        const response = await eSocialService.rubricAutomationConfig.incidenceNatureList(requestData, pagination);
        response.data.content.forEach((type) => {
          type.codeName = `${type.rubricNatureCode} - ${type.rubricNatureName}`;
          type.itemTitle = `${type.rubricNatureCode} - ${type.rubricNatureName}`;
          type.itemId = type.rubricNatureCode;
          type.value = type.rubricNatureCode;
        });
        commit('SET_INCIDENCE_NATURE_LIST', response.data.content);
        let totalElements = response.data.totalElements;
        commit('SET_TOTAL_INCIDENCE_NATURE', totalElements);
        dispatch('setLoadingIncidenceNatureList', false);
        dispatch('setLoadingFilters', false);
      } catch (error) {
        commit('SET_INCIDENCE_NATURE_LIST', []);
        commit('SET_TOTAL_INCIDENCE_NATURE', 0);
        dispatch('setLoadingIncidenceNatureList', false);
        dispatch('setLoadingFilters', false);
        console.error('AG_ERROR Error at get nature list data:', error);
      }
    },
    applyFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', payload.filters);
      dispatch('searchIncidenceNature', payload.search);
      dispatch('fetchFilterSelectsData', payload.filters);
    },
    clearFilters({ commit, dispatch }) {
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      dispatch('fetchFilterSelectsData', filtersDefaultState());
      dispatch('searchIncidenceNature', '');
    },
    async exportIncidenceNature({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);
      let response = await eSocialService.rubricAutomationConfig.incidenceNatureExport(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
    async saveIncidenceNature({ commit, state }, payload) {
      let response = await eSocialService.rubricAutomationConfig.incidenceNatureSave(payload);
    },
    async fetchHistory({ commit, getters }, payload) {
      commit('CLEAR_ARR_HISTORIC');
      const response = await eSocialService.rubricAutomationConfig.history('incidence_by_nature', { code: payload });
      if (response) {
        const incidenceList = getters.getIncidenceList;
        const arrHistoric = response.data.map((element) => formatHistory(element, incidenceList)).filter(Boolean);
        commit('SET_ARR_HISTORIC', arrHistoric);
      }
    },
    setLoadingIncidenceNatureList({ commit }, payload) {
      commit('SET_LOADING_INCIDENCE_NATURE_LIST', payload);
    },
    setLoadingFilters({ commit }, payload) {
      commit('SET_LOADING_FILTERS', payload);
    },
    setIncidenceNaturePagination({ commit, state, dispatch }, payload) {
      let isSamePagination =
        payload.pagination.itemsPerPage === state.incidenceNaturePagination.itemsPerPage &&
        payload.pagination.page === state.incidenceNaturePagination.page &&
        payload.pagination.sortBy === state.incidenceNaturePagination.sortBy
          ? true
          : false;
      commit('SET_INCIDENCE_NATURE_PAGINATION', payload.pagination);
      if (!isSamePagination) {
        dispatch('searchIncidenceNature', payload.search);
      }
    },
  },
  mutations: {
    SET_TABLES_ANALYSIS_PARAMETERS(state, payload) {
      state.tablesAnalysisParameters = payload;
    },
    SET_TOTAL_TABLES_ANALYSIS_PARAMETERS(state, payload) {
      state.totalTablesAnalysisParameters = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = cloneDeep(payload);
    },
    SET_LOADING_INCIDENCE_NATURE_LIST(state, payload) {
      state.loadingIncidenceNatureList = payload;
    },
    SET_LOADING_FILTERS(state, payload) {
      state.loadingFilters = payload;
    },
    SET_SITUATION_LIST(state, payload) {
      state.situationList = payload;
    },
    SET_NATURE_TYPE_LIST(state, payload) {
      state.natureTypeList = payload;
    },
    SET_INCIDENCE_IRRF_LIST(state, payload) {
      state.incidenceIRRFList = payload;
    },
    SET_INCIDENCE_FGTS_LIST(state, payload) {
      state.incidenceFGTSList = payload;
    },
    SET_INCIDENCE_CPRP_LIST(state, payload) {
      state.incidenceCPRPList = payload;
    },
    SET_INCIDENCE_INSS_LIST(state, payload) {
      state.incidenceINSSList = payload;
    },
    SET_INCIDENCE_NATURE_LIST(state, payload) {
      state.incidenceNatureList = payload;
    },
    SET_USERS_TETO_REMUN_LIST(state, payload) {
      state.usesTetoRemunList = payload;
    },
    SET_TOTAL_INCIDENCE_NATURE(state, payload) {
      state.totalIncidenceNature = payload;
    },
    SET_ARR_HISTORIC(state, payload) {
      state.arrHistoric = payload;
    },
    CLEAR_ARR_HISTORIC(state) {
      state.arrHistoric = [];
    },
    SET_INCIDENCE_NATURE_PAGINATION(state, payload) {
      state.incidenceNaturePagination = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
  },
};
