import App from '@/App.vue';
import i18n from '@/plugins/vue-i18n';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';

import '@/plugins';
import '@/filters';
import 'material-symbols';

Vue.config.productionTip = false;

export const eventBus = new Vue({
  i18n,
  store,
  router,
  vuetify,
  devServer: {
    proxy: 'http://localhost:9070',
    devServer: {
      headers: {
        'X-Frame-Options': 'SAMEORIGIN',
      },
    },
  },

  render: (h) => h(App),
}).$mount('#app');
