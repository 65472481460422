import rubrics from '@/assets/json/rubrics';
import ClassIndicadoresFilter from '@/router/views/indicadores/class/ClassIndicadoresFilter';
import eSocialService from '@/services/eSocialService.js';
import indexService from '@/services/indexService.js';
import { debounce, isEmpty, sortBy } from 'lodash';
import { cloneDeep } from 'lodash';

export default {
  namespaced: true,

  state: {
    loading: true,
    companies: [],
    establishments: [],
    categoryCode: [],
    rubrics: rubrics,
    periods: [],
    dataIndex: null,
    filter: {
      establishments: [],
      categories: [],
      rubrics: [],
      periods: [],
    },
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_ESTABLISHMENTS(state, payload) {
      state.establishments = payload;
    },
    SET_CATEGORIES(state, payload) {
      state.categoryCode = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_PERIODS(state, payload) {
      state.periods = payload;
    },
    SET_DATA_INDEX(state, payload) {
      state.dataIndex = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    async init({ commit, dispatch, state }, filter) {
      commit('SET_PERIODS', mountPeriods());
      await dispatch('fetchCompanies');

      const filterComputed = filter.isEmpty || isEmpty(filter) ? mountInitialFilter(state) : filter;

      commit('SET_FILTER', filterComputed);
      dispatch('fetchGraphs');

      return state.filter;
    },

    applyFilter({ commit, dispatch }, filter) {
      commit('SET_FILTER', cloneDeep(filter));

      dispatch('fetchGraphsDebounce');
    },

    async fetchCompanies({ commit, dispatch }) {
      let { data } = await eSocialService.establishment.getByUserId();

      let companies = data.filter((v, i, a) => a.findIndex((t) => t.cnpjMatriz === v.cnpjMatriz) === i);
      companies = sortBy(companies, ['socialName', 'stateCode', 'city']);
      let establishments = data.filter((v, i, a) => a.findIndex((t) => t.cnpjFilial === v.cnpjFilial) === i);
      establishments = sortBy(establishments, ['socialName', 'stateCode', 'city', 'cnpjFilial']);

      commit('SET_COMPANIES', companies);
      commit('SET_ESTABLISHMENTS', establishments);
      await dispatch('fetchCategories');
    },

    async fetchCategories({ commit }) {
      const response = await indexService.indexProcess.getCategories();
      let dataReturned = response.data;

      commit('SET_CATEGORIES', dataReturned);
    },

    fetchGraphsDebounce: debounce(({ dispatch }) => {
      return dispatch('fetchGraphs');
    }, 500),

    async fetchGraphs({ commit, state }) {
      commit('SET_LOADING', true);

      try {
        commit('SET_DATA_INDEX', null);
        const { rubrics, establishments, periods, categories } = state.filter;

        if (rubrics.length && establishments.length && periods.length) {
          const data = await indexService.evtremuns.get(rubrics, establishments, periods, categories);

          data.graphOne = data.graphOne.map((item) => {
            let code = item.name.substring(0, 4);

            item.name = item.name.replace('-', `- ${state.rubrics.find((x) => x.codigo == code).nome}`);

            return item;
          });
          if (!isEmpty(data)) commit('SET_DATA_INDEX', data);
        }
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};

function mountPeriods() {
  let periods = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2010; year <= currentYear; year++) {
    periods.push(year);
  }

  return periods.reverse();
}

function mountInitialFilter(state) {
  const firstEstablishments = state.establishments[0];
  const establishments = state.establishments
    .filter((item) => item.cnpjMatriz === firstEstablishments.cnpjMatriz)
    .map((item) => item.cnpjFilial);

  const filter = new ClassIndicadoresFilter();
  filter.establishments = establishments;
  filter.rubrics = [state.rubrics[0].codigo.toString()];
  filter.periods = state.periods[0].toString();

  return filter;
}
