Vue.use(Vuex);

import cadastrosGerenciais from '@/store/modules/cadastros-gerenciais';
import dashboard from '@/store/modules/dashboardModule';
import documents from '@/store/modules/documentsModule';
import ecac from '@/store/modules/ecacModule';
import egov from '@/store/modules/eGovModule';
import esocial from '@/store/modules/esocial';
import indicadores from '@/store/modules/indicadoresModule';
import notifications from '@/store/modules/notificationsModule';
import notify from '@/store/modules/NotifyModule';
import routerModule from '@/store/modules/routerModule';
import totalizadores from '@/store/modules/totalizadoresModule';
import user from '@/store/modules/userModule';
import usuarios from '@/store/modules/usuariosModule';
import wss from '@/store/modules/wssModule';
import Vue from 'vue';
import Vuex from 'vuex';

const store = new Vuex.Store({
  modules: {
    esocial,
    dashboard,
    ecac,
    user,
    usuarios,
    cadastrosGerenciais,
    indicadores,
    notifications,
    routerModule,
    wss,
    egov,
    documents,
    totalizadores,
    notify,
  },
});

export default store;
