import VCurrencyField from 'v-currency-field';
import Vue from 'vue';
import { VTextField } from 'vuetify/lib';

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});
