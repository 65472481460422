import router from '@/router/index.js';
import { axiosCertidao } from '@/services/config.js';

axiosCertidao.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {

    if (error.response.data.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push({ name: 'RouteLogin' });
    }
    return Promise.reject(error);
  },
);

export default {
  certidoes: {
    get: () => {
      return axiosCertidao.get('certificate');
    },
    filter: (params) => {
      return axiosCertidao.post('certificate/filter', params);
    },
    post: (params) => {
      return axiosCertidao.post('certificate', params);
    },
    put: (params) => {
      return axiosCertidao.put('certificate', params);
    },
    delete: (idCertidao) => {
      return axiosCertidao.delete('certificate/' + idCertidao);
    },
    getHistorico: (idCertidao) => {
      return axiosCertidao.get('certificate/historic/' + idCertidao);
    },
    getPendency: () => {
      return axiosCertidao.get('certificate/haspendentcnds');
    },
    confirmPendentsCndsMsg: () => {
      return axiosCertidao.put('certificate/confirmdisplayedcnds');
    }
  },
};
