import store from '@/store';

export default async function validateRole(permisions, { next, router }) {
  await store.dispatch('user/getUser');

  let roles = store.getters['user/roles'];

  let hasRole = permisions.some((permision) => roles.includes(permision));

  if (hasRole) {
    let message = '';
    store.dispatch('user/setMessage', message);
    // if (permisions.includes('ROLE_MENU_INTELLIGENCE')) {
    //   store.dispatch('user/setMessageLogin', message);
    // }

    return next();
    //return router.push({ name: to.name });
  } else {
    let message =
      'Esta funcionalidade não está disponível no seu plano de serviço. Solicite este modulo para seu consultor da AGNES.';
    store.dispatch('user/setMessage', message);
  }

  if (!localStorage.getItem('access_Token')) {
    return router.push({ name: 'RouteLogin' });
  }
}
